<script>
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import SideButtons from '../../../../../../../components/side-buttons.vue';
import {fetchProductsUomsApi,fetchProductsCategoriesApi,fetchWarehousesApi, fetchContractsListApi} from '@/api/common';
import {
  required,
} from "vuelidate/lib/validators";
export default {
  components: {Multiselect,SideButtons},
  import :{fetchProductsUomsApi,fetchProductsCategoriesApi,fetchWarehousesApi, fetchContractsListApi},
  props:['selectedProduct'],
  data() {
    return {
      title: "Nouveau produit",
      uoms: [],
      categories: [],
      waerhouses: [],
      contracts: [],
      select:false,
      periods: [
        {id:'days', name:'Jours'},
        {id:'months', name:'Mois'},
        {id:'years', name:'Années'},
      ],
      selectedPeriod:'',
      items: [
        {
          text: "Stock",
          href: "/"
        },
        {
          text: "Produits",
          href: "/"
        },
        {
          text: "Nouveau",
          active: true
        }
      ],
      productForm: {
        name: "",
        // unit_price: "",
        uom_id: "",
        // contract_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        family_id: "",
        subfamily_id: "",
        description: "",
        barcode: "",
        available_in_requests: false,
        has_batch_expiration_date:false,
        lifetime:'',
        lifetime_period:'',
      },
      subcategories:[],
      families:[],
      subfamilies:[],
      selectedCategory:[],
      selectedSubCategory:[],
      selectedFamily:[],
      selectedSubFamily:[],
      selectedWarehouse:[],
      selectedUom:[],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      disabled: false,
      up:false,
      getEmit:false,
      info:false,
      add:false,
      save:false,
      confirm:false,
      validate:false,
      edit:false,
      resetForm:false,
      SaveEdit:false,
      destroy:false,
      reset:false,
    };
  },
  validations: {
    productForm: {
      name: { required },
      // unit_price: { required },
      uom_id: { required },
      // contract_id: "",
      // warehouse_id: "",
      category_id: { required },
      // subcategory_id: { required },
      // family_id: { required },
      // subfamily_id: { required },
      // description: { required },
      barcode: { required },
      // available_in_requests: "",
      // batch_number:'',
      // expiration_date:'',
      // lifetime:'',
      // death_date:'',
    },
    
  },
  created: function(){
    // this.fetchContracts();
    this.fetchProductsUoms();
    this.fetchProductsCategories();
    this.fetchWarehouses();
  },
  watch:{
      selectedProduct:{
        handler(val){
          if(val && val[0] && val.length > 0){
            this.productForm = {
                              id                       : val[0].id,
                              uuid                     : val[0].uuid,
                              name                     : val[0].name,
                              uom_id                   : val[0].uom.id,
                              warehouse_id             : val[0].warehouse.id,
                              category_id              : val[0].category.id,
                              subcategory_id           : val[0].subcategory ? val[0].subcategory.id : "",
                              family_id                : val[0].family ? val[0].family.id : "",
                              subfamily_id             : val[0].subfamily ? val[0].subfamily.id : "",
                              description              : val[0].description,
                              barcode                  : val[0].barcode,
                              available_in_requests    : val[0].available_in_requests,
                              has_batch_expiration_date: val[0].has_batch_expiration_date,
                          };
            this.selectedWarehouse.push(val[0].warehouse);
            this.selectedUom.push(val[0].uom);
            this.selectedCategory.push(val[0].category);
            if(val[0].subcategory) this.selectedSubCategory.push(val[0].subcategory);
            if(val[0].family)this.selectedFamily.push(val[0].family);
            if(val[0].subfamily)this.selectedSubFamily.push(val[0].subfamily);
            
            this.subcategories = val[0].category.subcategories;
            // console.log(this.subcategories);
            this.select = true;
          }
          else {this.resetData();this.select=false}
          
        }
      },
      add:{
            handler(val){
              this.resetForm=false;
                if(val && this.$can('create_product')){
                    // console.log("clearForm");
                    this.disabled = true;
                    this.resetData();
                    this.resetForm=false;
                }
                else if (val && !this.$can('create_product')){ this.resetForm=true; Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')}
            }
        },
        
        edit:{
            handler(val){
                this.resetForm=false;
                if(val && this.$can('edit_product')){
                    this.disabled=true;
                    this.resetForm=false;
                }
                else if (val && !this.$can('create_product')){ this.resetForm=true; Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')}
            }
        },
        resetForm:{
            handler(val){
                if(val) {
                    console.log('reset');
                    this.resetData();
                    this.disabled=false;
                    this.select=false;
                }
            }
        },
        destroy:{
            handler(val){
                this.resetForm=false;
                if(val && this.$can('delete_product')){
                  this.deleteProduct(this.productForm.name, this.productForm.uuid);
                  this.resetForm=true;
                }
                else if (val && !this.$can('create_product')){ this.resetForm=true; Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')}
            }
        },

  },
  methods: {
    fetchProductsUoms(){
      fetchProductsUomsApi()
      .then((res) => (this.uoms = res.data.original.data))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchProductsCategories(){
      fetchProductsCategoriesApi()
      .then((res) => {
          this.categories = res.data.data
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchWarehouses(){
      fetchWarehousesApi()
      .then((res) => (this.waerhouses = res.data.original.data))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    idPeriod(period){
        this.productForm.lifetime_period = period.id
    },
    getCatID(category){
      console.log(category.subcategories);
      if(category.subcategories && category.subcategories.length > 0) this.subcategories = category.subcategories;
      this.productForm.category_id = category.id;
      this.selectedSubCategory = [];
    },
    getSubCatID(subcategory){
      this.families = subcategory.families;
      this.productForm.subcategory_id = subcategory.id;
      this.selectedFamily = [];
    },
    getFamID(family){
      this.subfamilies = family.subfamilies;
      this.productForm.family_id = family.id;
      this.selectedSubFamily = [];
    },
    getSubFamID(subfamily){
      this.productForm.subfamily_id = subfamily.id;
    },
    getWarehouseID(warehouse){
      this.productForm.warehouse_id = warehouse.id;
    },
    getUomID(uom){
      this.productForm.uom_id = uom.id;
    },
    
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if(this.$v.productForm.$invalid){this.$toast.error("Veuillez Vérifier vos informations");}
      else{
            this.$http.post('/stock/products/store', this.productForm)
            .then((res) => {
                var status = res.data.original.status;
                switch(status){
                  case 200: 
                    this.$toast.success(res.data.original.msg);
                    this.$v.$reset()
                    this.resetData();
                    this.$emit('refresh',true);
                    this.resetForm = true;
                    this.disabled=false;
                    this.add=false;
                    this.selectedCategory=[];
                    this.selectedSubCategory=[];
                    this.selectedFamily=[];
                    this.selectedSubFamily=[];
                    
                  break;

                  case 202: 
                    this.$toast.warning(res.data.original.msg);
                    this.$v.$reset()
                    this.resetData();
                    this.$emit('refresh',true);
                    this.resetForm = true;
                    this.disabled=false;
                    this.add=false;
                    this.selectedCategory=[];
                    this.selectedSubCategory=[];
                    this.selectedFamily=[];
                    this.selectedSubFamily=[];
                    
                  break;

                  case 500: 
                    this.$toast.warning(res.data.original.msg);
                  break;
                }
            })
            .catch((error) => {
                this.$toast.error(error.message);
            }).finally(() => {
            });
          }
      
    },
    updateProduct() {
      this.submitted = true;
      this.$v.$touch();
      if(this.$v.productForm.$invalid){this.$toast.error("Veuillez Vérifier vos informations");}
      else{
          this.$http.post('/stock/products/update', this.productForm)
            .then((res) => {
                var status = res.data.original.status;
                switch(status){
                  case 200: 
                    this.$toast.success(res.data.original.msg);
                    this.$emit('refresh',true);
                    // router.push({ name: 'base.stock.products' });
                    this.$v.$reset()
                    this.resetData();
                    this.disabled = false;
                    this.resetForm = true;
                    this.edit = false;
                    this.selectedCategory=[];
                    this.selectedSubCategory=[];
                    this.selectedFamily=[];
                    this.selectedSubFamily=[];
                  break;

                  case 500: 
                    this.$toast.warning(res.data.original.msg);
                  break;
                }
            })
            .catch((error) => {
                this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      
    },
    resetData(){
      this.productForm = {
                            name                     : "",
                            // unit_price            : "",
                            uom_id                   : "",
                            // contract_id           : "",
                            warehouse_id             : "",
                            category_id              : "",
                            subcategory_id           : "",
                            family_id                : "",
                            subfamily_id             : "",
                            description              : "",
                            barcode                  : "",
                            available_in_requests    : false,
                            has_batch_expiration_date: false,
                            lifetime                 : '',
                            lifetime_period          : '',
                          }
        this.selectedWarehouse = [];
        this.selectedUom = [];
        this.selectedCategory=[];
        this.selectedSubCategory=[];
        this.selectedFamily=[];
        this.selectedSubFamily=[];
        
    },
    deleteProduct(deptName, deptUid) {
       var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le produit: " + deptName + "?",
        text: "Cette Action est irréversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor:"#f46a6a",
        cancelButtonColor:  "#34c38f",
        confirmButtonText: '<i class="fas fa-trash"></i> Supprimer!',
        cancelButtonText: '<i class="far fa-times-circle"></i> Annuler!',
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/stock/products/delete/" + deptUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  _this.$toast.success(res.data.original.msg);
                  // this.fetchProdusList();
                  this.$emit('refresh',true);
                  this.resetData();
                  this.disabled = false;
                  this.destroy=false;
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              _this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

   
  }
};
</script>

<template>
    <div class="row">
      <SideButtons 
      :reset="resetForm" 
      :canEdit="!select" 
      @add="add = $event" 
      @edit=" edit = $event" 
      @resetEmit="resetForm = $event"  
      @delete="destroy = $event" />

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <fieldset :disabled="!disabled">
            <form class="needs-validation" @submit.prevent="formSubmit">
              
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="deptName">Désignation *</label>
                    <input
                      id="deptName"
                      v-model="productForm.name"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.productForm.name.$error }"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="deptName">Code a barre</label>
                    <input
                      id="deptName"
                      v-model="productForm.barcode"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.productForm.barcode.$error }"
                    />
                  </div>
                </div>
                
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">Unité de mesure</label>
                    <multiselect v-model="selectedUom" :select-label="''" class="multiselect" :disabled="!disabled" :class="{'disabledMS':!disabled}" @select="getUomID" :searchable="true" track-by="id" label="designation" :options="uoms" placeholder="Unité" :allow-empty="false">
                      <template slot="singleUom" slot-scope="{ uom }">{{ uom.name }}</template>
                    </multiselect>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">Magasin</label>
                    <multiselect v-model="selectedWarehouse" :select-label="''" class="multiselect" :disabled="!disabled" :class="{'disabledMS':!disabled}"  @select="getWarehouseID" :searchable="true" track-by="id" label="name" :options="waerhouses" placeholder="Magasin" :allow-empty="false">
                      <template slot="singleWarehouse" slot-scope="{ warehouse }">{{ warehouse.name }}</template>
                    </multiselect>
                  </div>
                </div>

                <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="deptName">Prix unitaire (DZD) *</label>
                    <input
                      id="deptName"
                      v-model="productForm.unit_price"
                      type="number"
                      min="1"
                      class="form-control"
                      placeholder="1250"
                      value=""
                      :class="{ 'is-invalid': submitted && $v.productForm.unit_price.$error }"
                    />
                  </div>
                </div> -->
                
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">Catégorie</label>
                    <multiselect v-model="selectedCategory" :select-label="''" :deselect-label="''" :disabled="!disabled" @select="getCatID" :class="{'disabledMS':!disabled}" :searchable="true" track-by="id" label="name" :options="categories" placeholder="Catégorie" :allow-empty="false">
                      <template slot="singleCateg" slot-scope="{ category }" >{{ category.name }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">Sous-Catégorie</label>
                    <multiselect v-model="selectedSubCategory" :select-label="''" :deselect-label="''" :disabled="!disabled" @select="getSubCatID" :class="{'disabledMS':!disabled}" :searchable="true" track-by="id" label="name" :options="subcategories" placeholder="Sous-Catégorie" :allow-empty="false">
                      <template slot="singleCateg" slot-scope="{ subcategory }">{{ subcategory.name }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">Famille</label>
                    <multiselect v-model="selectedFamily" :select-label="''" :deselect-label="''" :disabled="!disabled" @select="getFamID" :class="{'disabledMS':!disabled}" :searchable="true" track-by="id" label="name"  :options="families" placeholder="Famille" :allow-empty="false">
                      <template slot="singleCateg" slot-scope="{ family }">{{ family.name }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">Sous-Famille</label>
                    <multiselect v-model="selectedSubFamily" :select-label="''" :deselect-label="''" :disabled="!disabled" @select="getSubFamID" :class="{'disabledMS':!disabled}" :searchable="true" track-by="id" label="name" :options="subfamilies" placeholder="Sous-Famille" :allow-empty="false">
                      <template slot="singleCateg" slot-scope="{ subfamily }">{{ subfamily.name }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>

              <!-- <div class="row">
                
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">durée de vie</label>
                    <input type="number" min="0" class="form-control" v-model="productForm.lifetime">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">Type periode</label>
                    <multiselect 
                      v-model="selectedPeriod" 
                      class="multiselect" 
                      :disabled="!disabled"
                      :deselect-label="''"
                      :class="{'disabledMS':!disabled}"
                      track-by="id" label="name" 
                      :options="periods" 
                      :searchable="false" 
                      :select-label="''"
                      :allow-empty="false" 
                      @selectionChanged="selectedPeriod = $event "
                      @select="idPeriod"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                    </multiselect>
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col-md-4">
                  <b-form-checkbox
                      v-model="productForm.available_in_requests"
                      switch
                      class="mb-3"
                      >Disponible dans les requêtes</b-form-checkbox
                    >
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <b-form-checkbox
                      v-model="productForm.has_batch_expiration_date"
                      switch
                      class="mb-3"
                      >Ce produit a un N° Lot et une date d'éxpiration</b-form-checkbox
                    >
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom02">Description</label>
                    <textarea class="form-control" v-model="productForm.description" id="" cols="30" rows="1"></textarea>
                    
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                    <div class="col text-right">
                        <b-button v-if="add"  @click="formSubmit" variant="primary" class="btn-label mr-2">
                            <i class="far fa-save text-white label-icon"></i> Enregitrer
                        </b-button>
                        <b-button v-if="edit"  @click="updateProduct" variant="primary" class="btn-label mr-2">
                            <i class="far fa-save text-white label-icon"></i> Enregitrer
                        </b-button>

                        <b-button v-if="add || edit"  @click="resetForm = true" variant="light" class="btn-label mr-2">
                            <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                        </b-button>


                    </div>
                </div>
              
            </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>

</template>