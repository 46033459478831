var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("SideButtons", {
        attrs: { reset: _vm.resetForm, canEdit: !_vm.select },
        on: {
          add: function($event) {
            _vm.add = $event
          },
          edit: function($event) {
            _vm.edit = $event
          },
          resetEmit: function($event) {
            _vm.resetForm = $event
          },
          delete: function($event) {
            _vm.destroy = $event
          }
        }
      }),
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("fieldset", { attrs: { disabled: !_vm.disabled } }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Désignation *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.productForm.name,
                              expression: "productForm.name"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.productForm.name.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.productForm.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.productForm,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Code a barre")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.productForm.barcode,
                              expression: "productForm.barcode"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.productForm.barcode.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.productForm.barcode },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.productForm,
                                "barcode",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "validationCustom02" } },
                            [_vm._v("Unité de mesure")]
                          ),
                          _c("multiselect", {
                            staticClass: "multiselect",
                            class: { disabledMS: !_vm.disabled },
                            attrs: {
                              "select-label": "",
                              disabled: !_vm.disabled,
                              searchable: true,
                              "track-by": "id",
                              label: "designation",
                              options: _vm.uoms,
                              placeholder: "Unité",
                              "allow-empty": false
                            },
                            on: { select: _vm.getUomID },
                            scopedSlots: _vm._u([
                              {
                                key: "singleUom",
                                fn: function(ref) {
                                  var uom = ref.uom
                                  return [_vm._v(_vm._s(uom.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selectedUom,
                              callback: function($$v) {
                                _vm.selectedUom = $$v
                              },
                              expression: "selectedUom"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "validationCustom02" } },
                            [_vm._v("Magasin")]
                          ),
                          _c("multiselect", {
                            staticClass: "multiselect",
                            class: { disabledMS: !_vm.disabled },
                            attrs: {
                              "select-label": "",
                              disabled: !_vm.disabled,
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.waerhouses,
                              placeholder: "Magasin",
                              "allow-empty": false
                            },
                            on: { select: _vm.getWarehouseID },
                            scopedSlots: _vm._u([
                              {
                                key: "singleWarehouse",
                                fn: function(ref) {
                                  var warehouse = ref.warehouse
                                  return [_vm._v(_vm._s(warehouse.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selectedWarehouse,
                              callback: function($$v) {
                                _vm.selectedWarehouse = $$v
                              },
                              expression: "selectedWarehouse"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "validationCustom02" } },
                            [_vm._v("Catégorie")]
                          ),
                          _c("multiselect", {
                            class: { disabledMS: !_vm.disabled },
                            attrs: {
                              "select-label": "",
                              "deselect-label": "",
                              disabled: !_vm.disabled,
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.categories,
                              placeholder: "Catégorie",
                              "allow-empty": false
                            },
                            on: { select: _vm.getCatID },
                            scopedSlots: _vm._u([
                              {
                                key: "singleCateg",
                                fn: function(ref) {
                                  var category = ref.category
                                  return [_vm._v(_vm._s(category.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selectedCategory,
                              callback: function($$v) {
                                _vm.selectedCategory = $$v
                              },
                              expression: "selectedCategory"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "validationCustom02" } },
                            [_vm._v("Sous-Catégorie")]
                          ),
                          _c("multiselect", {
                            class: { disabledMS: !_vm.disabled },
                            attrs: {
                              "select-label": "",
                              "deselect-label": "",
                              disabled: !_vm.disabled,
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.subcategories,
                              placeholder: "Sous-Catégorie",
                              "allow-empty": false
                            },
                            on: { select: _vm.getSubCatID },
                            scopedSlots: _vm._u([
                              {
                                key: "singleCateg",
                                fn: function(ref) {
                                  var subcategory = ref.subcategory
                                  return [_vm._v(_vm._s(subcategory.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selectedSubCategory,
                              callback: function($$v) {
                                _vm.selectedSubCategory = $$v
                              },
                              expression: "selectedSubCategory"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "validationCustom02" } },
                            [_vm._v("Famille")]
                          ),
                          _c("multiselect", {
                            class: { disabledMS: !_vm.disabled },
                            attrs: {
                              "select-label": "",
                              "deselect-label": "",
                              disabled: !_vm.disabled,
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.families,
                              placeholder: "Famille",
                              "allow-empty": false
                            },
                            on: { select: _vm.getFamID },
                            scopedSlots: _vm._u([
                              {
                                key: "singleCateg",
                                fn: function(ref) {
                                  var family = ref.family
                                  return [_vm._v(_vm._s(family.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selectedFamily,
                              callback: function($$v) {
                                _vm.selectedFamily = $$v
                              },
                              expression: "selectedFamily"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "validationCustom02" } },
                            [_vm._v("Sous-Famille")]
                          ),
                          _c("multiselect", {
                            class: { disabledMS: !_vm.disabled },
                            attrs: {
                              "select-label": "",
                              "deselect-label": "",
                              disabled: !_vm.disabled,
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.subfamilies,
                              placeholder: "Sous-Famille",
                              "allow-empty": false
                            },
                            on: { select: _vm.getSubFamID },
                            scopedSlots: _vm._u([
                              {
                                key: "singleCateg",
                                fn: function(ref) {
                                  var subfamily = ref.subfamily
                                  return [_vm._v(_vm._s(subfamily.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selectedSubFamily,
                              callback: function($$v) {
                                _vm.selectedSubFamily = $$v
                              },
                              expression: "selectedSubFamily"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: { switch: "" },
                            model: {
                              value: _vm.productForm.available_in_requests,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.productForm,
                                  "available_in_requests",
                                  $$v
                                )
                              },
                              expression: "productForm.available_in_requests"
                            }
                          },
                          [_vm._v("Disponible dans les requêtes")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: { switch: "" },
                            model: {
                              value: _vm.productForm.has_batch_expiration_date,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.productForm,
                                  "has_batch_expiration_date",
                                  $$v
                                )
                              },
                              expression:
                                "productForm.has_batch_expiration_date"
                            }
                          },
                          [
                            _vm._v(
                              "Ce produit a un N° Lot et une date d'éxpiration"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "validationCustom02" } }, [
                          _vm._v("Description")
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.productForm.description,
                              expression: "productForm.description"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { id: "", cols: "30", rows: "1" },
                          domProps: { value: _vm.productForm.description },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.productForm,
                                "description",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col text-right" },
                      [
                        _vm.add
                          ? _c(
                              "b-button",
                              {
                                staticClass: "btn-label mr-2",
                                attrs: { variant: "primary" },
                                on: { click: _vm.formSubmit }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "far fa-save text-white label-icon"
                                }),
                                _vm._v(" Enregitrer ")
                              ]
                            )
                          : _vm._e(),
                        _vm.edit
                          ? _c(
                              "b-button",
                              {
                                staticClass: "btn-label mr-2",
                                attrs: { variant: "primary" },
                                on: { click: _vm.updateProduct }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "far fa-save text-white label-icon"
                                }),
                                _vm._v(" Enregitrer ")
                              ]
                            )
                          : _vm._e(),
                        _vm.add || _vm.edit
                          ? _c(
                              "b-button",
                              {
                                staticClass: "btn-label mr-2",
                                attrs: { variant: "light" },
                                on: {
                                  click: function($event) {
                                    _vm.resetForm = true
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-times-circle text-danger label-icon"
                                }),
                                _vm._v(" Annuler ")
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }